import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call from "../assets/images/call.png";

const Termsconditions = () => {
    return (
        <>
         <section className="myheader">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
                        <div className="mylogo">
                            <a href="/">
                              <h3 className="mynewlogo">Insurance Department Online</h3>
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:0800 000 0000">0800 000 0000</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="privacy-policy">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="headw">
                                <h2>Terms & Conditions</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="main-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                           <h4>Terms of use </h4>
                           <p>By using this website, you acknowledge that you have read and agreed to the terms of use as outlined here. If you don't agree to these terms of use, please refrain from using our website. </p>
                           <p>The following terms apply to these terms of Use, and Privacy Statement as well as any Agreements:</p>
                           <ul>
                            <li>"Client", "You", and "Your" refer to you, the person accessing this website and accepting the terms and conditions of our Company.</li>
                            <li>"The Company", "Ourselves", "We", and "Us" refer to our Company.</li>
                            <li>"Party", "Parties", or "Us" refers to both the Client and ourselves, or either the Client or ourselves. </li>
                           </ul>
                           <h4>Access to website </h4>
                           <p>Your access to and use of this website, including all information, products, and services available on or through the website, are provided subject to these terms and conditions. By accessing or using the website, you agree to be bound by these terms and conditions, which govern your access to and use of the website. If you do not agree to these terms and conditions, you should not access or use the website.</p>
                           <h4>Intellectual property </h4>
                           <p>Unless otherwise stated, all intellectual property rights (including but not limited to copyrights, trademarks, patents, trade secrets, and any other proprietary rights) in the content, materials, and design of this website belong to us or our licensors.You may view, download, and print pages from the website for your personal, non-commercial use only. Any other use, including reproduction, modification, distribution, transmission, display, or performance of the content of this website is strictly prohibited without our prior written consent.</p>
                           <p>All trademarks, service marks, and logos displayed on this website are our registered and unregistered trademarks. Nothing on this website should be construed as granting, by implication, estoppel, or otherwise, any licence or right to use any trademark without our prior written consent. </p>
                           <h4>Disclaimer </h4>
                           <p>The information provided on this website is for general informational purposes only. It is not intended to provide legal, financial, medical, or any other professional advice. You should not rely on this information as a substitute for professional advice tailored to your specific circumstances. While we strive to keep the information on this website accurate and up to date, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore, strictly at your own risk. </p>
                           <p>In no event will we be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
                            </p>
                            <h4>Links from this website </h4>
                            <p>This website may contain links to external websites that are not operated by us. These links are provided for your convenience and informational purposes only. We do not endorse, control, or assume responsibility for the content, privacy policies, or practices of any third-party websites linked to our website. You acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such linked websites.</p>
                            <h4>Limitation of liability </h4>
                            <p>To the fullest extent permitted by law, we and our directors, officers, employees, agents, suppliers, licensors, and service providers exclude all liability for any direct, indirect, special, incidental, consequential, punitive, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses, arising out of or in connection with your use of this website or any linked websites.</p>
                            <h4>Indemnification </h4>
                            <p>You agree to indemnify, defend, and hold harmless us and our partners from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees arising out of or relating to your violation of these terms of use or your use of the website, including, but not limited to, any use of the website's content, services, and products other than as expressly authorised in these terms of use or your use of any information obtained from the website.</p>
                            <h4>Force majeure </h4>
                            <p>Neither party shall be liable for any failure or delay in performance under these terms of use if such failure or delay is due to circumstances beyond its reasonable control, including but not limited to acts of God, war, terrorism, strikes, natural disasters, government regulations, or any other similar event. Any party affected by such an event shall inform the other party of the same and shall use all reasonable endeavours to comply with the terms and conditions of any agreement contained herein.</p>
                            <h4>Viruses, Offences, and Hacking:</h4>
                            <p>By using our website, you agree not to:</p>
                            <ul>
                                <li>Misuse our website by knowingly introducing viruses, trojans, worms, logic bombs, or other malicious or technologically harmful material.</li>
                                <li>Attempt to gain unauthorised access to our website, the server on which our website is stored, or any server, computer, or database connected to our website. </li>
                                <li>Attack our website via a denial-of-service attack or a distributed denial-of-service attack.</li>
                            </ul>
                            <p>Any breach of this provision may constitute a criminal offence. We will report any such breach to the relevant law enforcement authorities and cooperate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our website will cease immediately.</p>
                            <h4>Governing law </h4>
                            <p>These terms of use and any dispute or claim arising out of or in connection with them (including non-contractual disputes or claims) shall be governed by and construed in accordance with English law.</p>
                            <p>The courts of England and Wales shall have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with these terms of use. </p>
                            <h4>Changes to the terms of use </h4>
                            <p>We may modify these terms of use from time to time. You’re advised to visit this page regularly to stay informed about the updates in our terms of use. </p>
                            <h4>Contact us </h4>
                            <p>If you have any questions or queries regarding the terms of use, feel free to contact us at <a href="mailto:info@Insurance-department.online">info@Insurance-department.online.</a> </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home2-copy-right">
    <div className="container">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                <ul>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms-conditions">Terms & Conditions</a></li>
                    <li><a href="/Contact">Contact Us</a></li>
                </ul>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
            <p className="home2-colpyy">
             © Copyright 2024 <a href="/">Insurance-department.online</a>. All Rights Reserved.
              </p>
            </div>
        </div>
    </div>
</section>
            {/* <section className="copy-right01">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <p className="colpyy">
                            © Copyright 2024 <a href="/">Insurance claims online</a>. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
};

export default Termsconditions;
