import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call from "../assets/images/call.png";

const Privacypolicy = () => {
    return (
        <>
              <section className="myheader">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
                        <div className="mylogo">
                            <a href="/">
                              <h3 className="mynewlogo">Insurance Department Online</h3>
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:0800 000 0000">0800 000 0000</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="privacy-policy">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="headw">
                                <h2>Privacy Policy</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="main-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                           <h4>Privacy Policy  </h4>
                           <p>We are committed to protecting and respecting your privacy. This policy (together with our terms of use and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By using our website, you consent to the processing of your personal information as outlined in this privacy policy. Please read this privacy policy carefully before providing your personal information. </p>
                           <h4>What information we collect </h4>
                           <p>When you visit our website or communicate with us, we may collect personal information about you. This personal information may include:</p>
                           <ul>
                            <li>Personal details, including your name, email address, telephone number,  driving licence information and NI number details. </li>
                            <li>We also collect information when you fill in a form, complete a survey, etc.</li>
                            <li>No identifiable information, including your device information, browser type, pages accessed and IP address. </li>
                  
                           </ul>
                           <p>In case you provide us with any sensitive personal data, we will take adequate measures to safeguard it from unauthorised use and fraud. </p>
                           <h4>How we use your information </h4>
                           <p>We only ever use your personal information with your prior  consent, or to the extent necessary to:</p>
                           <ul>
                            <li>Perform contractual obligations </li>
                            <li>Comply with legal duty </li>
                            <li>Remember your preferences, such as keeping a record if you ask not to receive marketing material</li>
                            <li>Pursue our own lawful interests</li>
                            <li>Send you promotional messages </li>
                           </ul>
                           <p>We assure you that we will use your personal information for the purpose it was collected or for similar/related purposes. </p>
                           <h4>With whom we share your information </h4>
                           <p>We may disclose your data to third-party service providers for service delivery. These third parties may comprise:</p>
                        <ul>
                            <li>Entities or partners assisting us with your claim</li>
                            <li>Other parties involved in the claim, including their insurer, solicitor, or representative</li>
                            <li>Healthcare professionals, law enforcement, or other investigators</li>
                            <li>Designated engineers</li>
                            <li>Our solicitor panel</li>
                            <li>Repair shops or garages tasked with completing repairs</li>
                        </ul>
                        <h4>Security of your personal information </h4>
                        <p>We strive to safeguard your personal information from theft, fraud and unauthorised use. We have implemented robust security measures to safeguard your personal information. However, transmission of information over the Internet is not 100% secure. Therefore, we cannot guarantee the security of information transmitted via the Internet.</p>
                        <h4>Your rights </h4>
                        <p>You have certain rights regarding the personal information we hold about you. These rights include:</p>
                        <ul>
                            <li>Right to request access to your personal data.</li>
                            <li>Right to ask us to correct any inaccuracies in your personal data.</li>
                            <li>Right to request the deletion of your personal data under certain conditions.</li>
                            <li>Right to request the restriction of processing your personal data in specific circumstances.</li>
                            <li>Right to object to the processing of your personal data for direct marketing purposes or when processing is based on our legitimate interests.</li>
                            <li>Right to request to receive your personal data in a structured, commonly used, and machine-readable format and have the right to transmit those data to another controller.</li>
                            <li>Right to withdraw your consent regarding the processing of your personal information.</li>
                        </ul>
                        <h4>Changes to the privacy policy </h4>
                        <p>We may modify this privacy policy from time to time. You’re advised to visit this page regularly to stay updated with the changes in our privacy policy </p>
                        <h4>Contact us </h4>
                        <p>If you have any questions or queries regarding our privacy policy, feel free to contact us at  <a href="mailto:info@Insurance-department.online">info@Insurance-department.online.</a> </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home2-copy-right">
    <div className="container">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                <ul>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms-conditions">Terms & Conditions</a></li>
                    <li><a href="/Contact">Contact Us</a></li>
                </ul>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
            <p className="home2-colpyy">
             © Copyright 2024 <a href="/">Insurance-department.online</a>. All Rights Reserved.
              </p>
            </div>
        </div>
    </div>
</section>
            {/* <section className="copy-right01">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <p className="colpyy">
                            © Copyright 2024 <a href="/">Insurance claims online</a>. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
};

export default Privacypolicy;
